import React from 'react';

import * as Styled from './styles';
import Heading from '../../molecules/Heading';
import Accordion from '../../molecules/Accordion';
import { TitleFaqDataProps } from './types';

const TitleFaq: React.FC<TitleFaqDataProps> = ({ data }) => {
  const { title, items } = data;

  return (
    <Styled.TitleFaqInner>
      <Styled.TitleFaqHeading>
        <Heading {...{ title }} />
      </Styled.TitleFaqHeading>
      <Styled.TitleFaqAccordion>
        <Accordion {...{ items }} />
      </Styled.TitleFaqAccordion>
    </Styled.TitleFaqInner>
  );
};

export default TitleFaq;
