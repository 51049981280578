import { graphql } from 'gatsby';
import React from 'react';

import TitleFaq from '../../TitleFaq';
import { TitleFaqSectionProps } from './types';
import { AccordionItemProps } from '../../../molecules/Accordion/types';

const TitleFaqSection: React.VFC<TitleFaqSectionProps> = ({ title, faqsList }) => {
  return (
    <TitleFaq
      data={{
        title: title ?? '',
        items: (faqsList ?? []).map<AccordionItemProps>((singleFaq) => {
          const { _key, question: heading, answer: content } = singleFaq ?? {};

          return {
            key: _key ?? '',
            heading: heading ?? '',
            content: content ?? [],
          };
        }),
      }}
    />
  );
};

export const fragment = graphql`
  fragment TitleFaqSectionFragment on SanityTitleFaqSection {
    _key
    _type
    title
    faqsList {
      _key
      question
      answer: _rawAnswer(resolveReferences: { maxDepth: 10 })
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default TitleFaqSection;
