import styled, { css } from 'styled-components';

import { SingleAccordionStyledProps } from './types';
import media from '../../../common/MediaQueries';
import Typography from '../../styles/Typography';
import { Box, FlexBox } from '../../styles/Grid/styles';
import { transition } from '../../../common/mixins';

export const AccordionsWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.additional.dark};
`;

export const AccordionHead = styled(Box)`
  cursor: pointer;
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-top: 1px solid ${({ theme }) => theme.colors.additional.dark};
`;

export const AccordionTitle = styled(Typography).attrs({
  forwardedAs: 'h2',
})`
  padding-right: 4rem;
  ${({ theme }) => theme.headingS};

  @media ${media.tablet} {
    ${({ theme }) => theme.headingM};
  }
`;

export const AccordionIcon = styled(FlexBox)`
  ${transition('transform', '0.4s')}
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 1.5rem;
  transform: translateY(-50%) rotate(0);
`;

export const AccordionContent = styled(Box)`
  padding-bottom: 3rem;
`;

export const AccordionContentWrapper = styled(Box)`
  overflow: hidden;
  max-height: 0;
`;

export const Accordion = styled(Box)<SingleAccordionStyledProps>`
  ${({ isActive }) =>
    isActive &&
    css`
      ${AccordionContentWrapper} {
        max-height: none;
      }
      ${AccordionIcon} {
        transform: translateY(-50%) rotate(180deg);
      }
    `}
`;
