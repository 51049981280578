import React, { useState } from 'react';

import { IconArrowDown } from '../../../assets/svg';
import RichText from '../RichText';
import * as Styled from './styles';
import { AccordionProps } from './types';

const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [isSelected, setIsSelected] = useState(items.find((item) => item.isActive)?.key);

  return (
    <Styled.AccordionsWrapper aria-label="Accordion">
      {items.map(({ key, heading, content }) => (
        <Styled.Accordion key={`${key}-head`} isActive={isSelected === key}>
          <Styled.AccordionHead
            id={`${key}-tab`}
            aria-controls={`${key}-accordion`}
            aria-expanded={isSelected === key}
            onClick={() => (isSelected === key ? setIsSelected(undefined) : setIsSelected(key))}
          >
            <Styled.AccordionTitle>{heading}</Styled.AccordionTitle>
            <Styled.AccordionIcon>
              <IconArrowDown />
            </Styled.AccordionIcon>
          </Styled.AccordionHead>
          <Styled.AccordionContentWrapper aria-hidden={isSelected !== key} id={`${key}-accordion`}>
            <Styled.AccordionContent>
              <RichText data={content} />
            </Styled.AccordionContent>
          </Styled.AccordionContentWrapper>
        </Styled.Accordion>
      ))}
    </Styled.AccordionsWrapper>
  );
};

export default Accordion;
